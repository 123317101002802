// import './css/jquery.dataTables.css'
// import './jquery.dataTables.css'
// import 'datatables.net-dt/css/jquery.datatables.css'

import React, {Component} from 'react'
const $ = require('jquery')
$.DataTable = require('datatables.net') 
 
export class Table extends Component {
    componentDidMount() {
        console.log(this.el);
        this.$el = $(this.el)
        this.$el.DataTable({
            data: this.props.data, 
            columns: [  
                { title: "_id" },
                { title: "createdAt" }
            ]
        })
    }  
   
    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <table className="display" width="100%" ref = {el => this.el = el }></table>

            </div>
        );
    }
}