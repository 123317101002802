import React, {Component,useState,useEffect} from 'react'
import './App.css';
import {Table} from "./Table";    
import { ACCESS_TOKEN_NAME, API_BASE_URL , IMG_PATH } from '../../constants/apiConstants';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import { Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { SketchPicker } from 'react-color';
import $ from 'jquery'; 
import axios from 'axios';
import { ChromePicker } from 'react-color';
import reactCSS from 'reactcss'
import ReactColorPicker from '@super-effective/react-color-picker';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
//import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
//import Geocode from "react-geocode";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { triggerBase64Download } from 'react-base64-downloader';
import DataTable from 'react-data-table-component'; 
import { saveAs } from 'file-saver';
const { FaIcon, FaStack } = require('react-fa-icon');
// FileSaver saveAs(Blob/File/Url, optional DOMString filename, optional Object { autoBom })

const MySwal = withReactContent(Swal)
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})



function MyQrCodes(props) {
    
    //class MyQrCodes extends Component {
    //state = { dataSet: [] }
    // dataSet =  []
    const [ dataSet, setDataSet ] = useState([]);
    
    const handleDownloadPng=(e)=>{

      console.log(e.target.href);
      e.preventDefault();
      saveAs(e.target.href, "image.jpg");
      // var element = document.createElement("a");
      // var pth= e.target.href;
      // console.log(e.target)
      // // alert(e.target.href)
      // var file = new Blob(
      //   ["http://localhost:5003/uploads/qr_code_1610718912397.png"],
      //   { type: "image/*" }
      // );
      // element.href = URL.createObjectURL(file);
      // element.download = "image.jpg";
      // element.click();

    }
    const deleteQrCode = (obj) =>{

         var qrCodeId = obj._id;
         // alert(qrCodeId);
         
         axios.get(API_BASE_URL+'/deleteQrCode/'+qrCodeId ,{ headers: {'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME)}}).then(res => {
            
            Toast.fire({
              icon: 'success',
              title: 'Deleted successfully'
            })

            axios.get(API_BASE_URL+'/getAllQrCodes' ,{ headers: {'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME)}}).then(res => {
                const as = res.data.data;
                console.log(as)
                // this.setState({ dataSet });
                setDataSet(as); 
                
            })
            // redirectTomyQrCodes();
        })
    }

    useEffect(() => {
       axios.get(API_BASE_URL+'/getAllQrCodes' ,{ headers: {'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME)}}).then(res => {
            const as = res.data.data;
            console.log(as)
            // this.setState({ dataSet });
            setDataSet(as); 
            
        })
    },[]);


    const data = dataSet;
      const columns = [
        {
          name: 'ID',
          selector: '_id',
        },
        {
          name: 'Date',
          selector: 'createdAt',
          sortable: true,
        },
        {
          name: 'Qr Code',
          selector: 'qrCodeImage',
          cell: row => <a href="#" ><img src={API_BASE_URL + row.qrCodeImage} /></a>,
        },
        {
         name: 'Download',
         selector: 'action',
         cell: row => <a  href={API_BASE_URL + row.qrCodeImage} onClick={handleDownloadPng} >Download</a>,
       },
        {
         name: 'Delete',
         selector: 'action',
         cell: row => <a href="javascript:void(0)" onClick={() => deleteQrCode(row)}>Delete</a>,
       }, 
      ]; 
    console.log(dataSet)
    return(
        <div className="">
          <DataTable
             columns={columns}
             data={data}
             pagination={true}
             className="rwd-table rwd-table2 shadow"
             />
      </div>
    );
    

    
}

export default MyQrCodes;