import React, {useState} from 'react';
import axios from 'axios';
import './RegistrationForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";

function RegistrationForm(props) {
    const [state , setState] = useState({
        firstname : "",
        lastname : "",
        email : "",
        password : "",
        confirmPassword: "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const sendDetailsToServer = () => {
        if(state.email.length && state.password.length) {
            props.showError(null);
            const payload={
                "firstname":state.firstname,
                "lastname":state.lastname,
                "email":state.email,
                "password":state.password,
            }
            axios.post(API_BASE_URL+'/register', payload)
                .then(function (response) {
                    const qrEmailErr={}; 
                    if(response.data.status === 200){
                        setState(prevState => ({
                            ...prevState,
                            'successMessage' : 'Registration successful. Redirecting to login page..'
                        }))
                        localStorage.setItem('Authorization',response.data.token);
                        localStorage.setItem('firstname',state.firstname);
                        localStorage.setItem('lastname',state.lastname);
                        localStorage.setItem('email',state.email);
                        localStorage.setItem('mobile',"");
                        // redirectToHome();
                        redirectToLogin();

                        props.showError(null)
                    }else if(response.data.status == 400){
                        
                        qrEmailErr.emailEmpty="This E-mail address is already taken";
                        //isValid= false;
                        setEmailErr(qrEmailErr);
                        //props.showError("Email already exists");
                    }else{
                        props.showError("Some error ocurred");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });    
        } else {
            props.showError('Please enter valid email and password')    
        }
        
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login'); 
    }
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const isValid= formValidation(e.target.value);
        //alert(isValid);
        if(isValid==true){

          sendDetailsToServer(e);
            // if(state.password === state.confirmPassword) {
            //     sendDetailsToServer()    
            // } else {
            //     props.showError('Passwords do not match');
            // }
        }
    }
  
    if(localStorage.getItem('Authorization')!=null){
        redirectToHome();
    }
    const [ qrPasswordErr, setPasswordErr ] = useState({});
    const [ qrEmailErr, setEmailErr ] = useState({});
    const [ firstNameErr, setFirstNameErr ] = useState({});
    const [ lastNameErr, setLastNameErr ] = useState({});
    const [ confirmPasswordErr, setConfirmPasswordErr ] = useState({});

    const formValidation=()=>{
        
        const qrEmailErr={};   
        const qrPasswordErr={}; 
        const lastNameErr={}; 
        const firstNameErr={}; 
        const confirmPasswordErr={}; 

        let isValid = true;
        if(state.email=='' || state.email==undefined){
            qrEmailErr.emailEmpty="Please enter the E-mail";
            isValid= false;
            setEmailErr(qrEmailErr); 
        }else if(state.email!=''){
            var emailField= state.email;
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(state.email) == false) 
            {
                qrEmailErr.emailEmpty="Please enter a valid E-mail";
                isValid= false;
            }
            setEmailErr(qrEmailErr);
            //return isValid;
        }
        // alert(state.password);
        if (state.password=='' || state.password==undefined) {
            //alert('dd');
            qrPasswordErr.emailEmpty="Please enter the Password";
            isValid= false;
        }else if (state.password!='') {
            if(state.password.length<4){
                qrPasswordErr.emailEmpty="Password length must be greater then 4";
                isValid= false;
            }
        }
        if (state.firstname=='' || state.firstname==undefined) {
            //alert('dd');
            firstNameErr.emailEmpty="Please enter the first name";
            isValid= false;
        }
        if (state.lastname=='' || state.lastname==undefined) {
            //alert('dd');
            lastNameErr.emailEmpty="Please enter the lastname name";
            isValid= false;
        }
        if (state.confirmPassword!=state.password) {
            //alert('dd');
            confirmPasswordErr.emailEmpty="Password Mismatch";
            isValid= false;
        }
        setPasswordErr(qrPasswordErr);
        setFirstNameErr(firstNameErr);
        setLastNameErr(lastNameErr);
        setConfirmPasswordErr(confirmPasswordErr);
        return isValid;
      

    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
 
            <div className="form-group text-left">
                <label htmlFor="exampleInputFirstname">First name</label>
                <input type="text" 
                       className="form-control" 
                       id="firstname" 
                       aria-describedby="firstnameHelp" 
                       placeholder="Enter firstname" 
                       value={state.firstname}
                       onChange={handleChange}
                />
                {Object.keys(firstNameErr).map((key)=>{
                  return  <div style={{color:'red'}}>{firstNameErr[key]}</div>
                })}
                </div>
                   <div className="form-group text-left">
                <label htmlFor="exampleInputLastname">Last name</label>
                <input type="text" 
                       className="form-control" 
                       id="lastname" 
                       aria-describedby="lastHelp" 
                       placeholder="Enter lastname" 
                       value={state.lastname}
                       onChange={handleChange}
                />
                {Object.keys(lastNameErr).map((key)=>{
                  return  <div style={{color:'red'}}>{lastNameErr[key]}</div>
                })}
                </div>

                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email" 
                       value={state.email}
                       onChange={handleChange}
                />
                {Object.keys(qrEmailErr).map((key)=>{
                  return  <div style={{color:'red'}}>{qrEmailErr[key]}</div>
                })}
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Password"
                        value={state.password}
                        onChange={handleChange} 
                    />
                    {Object.keys(qrPasswordErr).map((key)=>{
                      return  <div style={{color:'red'}}>{qrPasswordErr[key]}</div>
                    })}
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="confirmPassword" 
                        placeholder="Confirm Password"
                        value={state.confirmPassword}
                        onChange={handleChange} 
                    />
                    {Object.keys(confirmPasswordErr).map((key)=>{
                      return  <div style={{color:'red'}}>{confirmPasswordErr[key]}</div>
                    })}
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Register
                </button>
            </form>
            
            <div className="mt-2">
                <span>Already have an account? </span>
                <span className="loginText" onClick={() => redirectToLogin()}>Login here</span> 
            </div>
            
        </div>
    )
}

export default withRouter(RegistrationForm);