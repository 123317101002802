
import React, {useState ,useEffect} from 'react';
import axios from 'axios';
import './ChangePassword.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";

function ChangePassword(props) {
    const [state , setState] = useState({
        currentPassword : "",
        newPassword : "",
        confirmPassword: "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }


    useEffect(() => {  
        localStorage.getItem(ACCESS_TOKEN_NAME);
 if (!localStorage.getItem(ACCESS_TOKEN_NAME)) {
    
        redirectToHome();
    // Update the document title using the browser API    
    }

     });


    const sendDetailsToServer = () => {
        if(state.currentPassword.length && state.newPassword.length) {
            props.showError(null);
            const payload={
                "currentPassword":state.currentPassword,
                "newPassword":state.newPassword,
                "confirmPassword":state.confirmPassword,
               // "Authorization" :localStorage.getItem(ACCESS_TOKEN_NAME)
                
            }
            axios.post(API_BASE_URL+'/update_password', payload ,{ headers: {'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME)}})
                .then(function (response) {
                    //alert(111111);
                   // alert(response.data.status);
                    if(response.data.status == 200){
                        setState(prevState => ({
                            ...prevState,
                            'successMessage' : 'Password update successful.'
                        }))
                        //localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);
                     //   redirectToHome();
                      //  props.showError(null)
                    }else if(response.data.status == 404){
                        console.log(response);
                        props.showError("User does not exists");
                                
                    } else{
                        props.showError("Some error ocurred");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });    
        } else {
            props.showError('Please enter valid newPassword')    
        }
        
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login'); 
    }
    const handleSubmitClick = (e) => {
        e.preventDefault();
        if(state.newPassword === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            props.showError('Passwords do not match');
        }
    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
               <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Old newPassword</label>
                    <input type="password" 
                        className="form-control" 
                        id="currentPassword" 
                        placeholder="Old newPassword"
                        value={state.currentPassword}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">New newPassword</label>
                    <input type="password" 
                        className="form-control" 
                        id="newPassword" 
                        placeholder="New newPassword"
                        value={state.newPassword}
                        onChange={handleChange} 
                    />
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Confirm newPassword</label>
                    <input type="password" 
                        className="form-control" 
                        id="confirmPassword" 
                        placeholder="Confirm newPassword"
                        value={state.confirmPassword}
                        onChange={handleChange} 
                    />
                </div>

                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    submit
                </button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
           
            
        </div>
    )
}

export default withRouter(ChangePassword);