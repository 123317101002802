import React, {useState} from 'react';
import axios from 'axios';
// import './LoginForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter,Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QRCode from 'qrcode.react';
const MySwal = withReactContent(Swal)
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
   
function Profile(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const userFirstName=localStorage.getItem('firstname');
	const userLastName=localStorage.getItem('lastname');
	const userEmail=localStorage.getItem('email');
	const userMobile=localStorage.getItem('mobile');
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const payload={
            "email":state.email,
            "password":state.password,
        }
       axios.post(API_BASE_URL+'/login', payload)
            .then(function (response) {
                if(response.data.status === 200){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Login successful. Redirecting to home page..'
                    }))
                  //  console.log(response.data.data);
                  //  localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);
                    // localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);
                    // localStorage.setItem('firstname',response.data.data.firstname);
                    // localStorage.setItem('lastname',response.data.data.lastname);
                    // localStorage.setItem('email',response.data.data.email);
                    // localStorage.setItem('mobile',response.data.data.mobile);

                    Toast.fire({
                        icon: 'success',
                        title: 'Signed In Successfully'
                    })
                    setTimeout(function() {
                        redirectToHome();
                    }, 1000);
                    props.showError(null)
                }
                else if(response.data.status == 400){
                    props.showError("Username and password do not match");
                }
                else if(response.data.status == 404){
                    props.showError("User does not exists");
                }
                else{
                    props.showError("Username does not exists");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToRegister = () => {
        props.history.push('/register'); 
        props.updateTitle('Register');
    }
    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME)
        props.history.push('/login')
    }
    return(
        <div class="container">
			<div class="row flex-lg-nowrap">
			   

			  <div class="col">
			    <div class="row">
			      <div class="col mb-3">
			        <div class="card">
			          <div class="card-body">
			            <div class="e-profile">
			              <div class="row">
			                 
			                <div class="col d-flex flex-column flex-sm-row justify-content-between mb-3">
			                  <div class="text-center text-sm-left mb-2 mb-sm-0">
			                    <h4 class="pt-sm-2 pb-1 mb-0 text-nowrap">{userFirstName} {userLastName}</h4>
			                  </div>
			                  <div class="text-center text-sm-right">
			                    <span class="badge badge-secondary">User</span> 
			                  </div>
			                </div>
			              </div>
			              <ul class="nav nav-tabs">
			                <li class="nav-item"><a href="javascript:void(0)" class="active nav-link">Settings</a></li>
			              </ul>
			              <div class="tab-content pt-3">
			                <div class="tab-pane active">
			                  <form class="form" novalidate="">
			                    <div class="row">
			                      <div class="col">
			                        <div class="row">
			                          <div class="col">
			                           
			                            <div class="form-group">
			                              <label>Full Name</label>
			                              <input class="form-control" disabled type="text" name="name" placeholder="" value={userFirstName} />
			                            </div>
			                          </div>
			                          <div class="col">
			                            <div class="form-group">
			                              <label>Last Name</label>
			                              <input class="form-control" disabled type="text" name="username" placeholder="johnny.s" value={userLastName}/>
			                            </div>
			                          </div>
			                        </div>
			                        <div class="row">
			                          <div class="col">
			                            <div class="form-group">
			                              <label>Email</label>
			                              <input class="form-control" disabled type="text" value={userEmail} placeholder="user@example.com"/>
			                            </div>
			                          </div>
			                        </div>
			                        <div class="row">
			                          <div class="col mb-3">
			                            <div class="form-group">
			                              <label>Mobile</label>
			                              <input class="form-control" disabled type="text" value={userMobile} placeholder="9876543210"/>
			                            </div>
			                          </div>
			                        </div>
			                      </div>
			                    </div>
			                    
			                    <div class="row">
			                      <div class="col d-flex justify-content-end">
			                        <Link to='/editprofile'>Edit Profile</Link>
			                      </div>
			                    </div>
			                  </form>

			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>

			      <div class="col-12 col-md-3 mb-3">
			        <div class="card mb-3">
			          <div class="card-body">
			            <div class="px-xl-3">
			              <button class="btn btn-block btn-secondary">
			                <i class="fa fa-sign-out"></i>
			                <Link to="/" onClick={() => handleLogout()}> <span>Logout</span></Link>
			              </button>
			            </div>
			          </div>
			        </div>
			        
			      </div>
			    </div>

			  </div>
			</div>
		</div>
    )
}

export default withRouter(Profile);