import React,{ useEffect} from 'react';
import { withRouter,Link } from "react-router-dom";
import { ACCESS_TOKEN_NAME, WEB_URL } from '../../constants/apiConstants';
function Header(props) {
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    
    let title = capitalize(props.location.pathname.substring(1,props.location.pathname.length))
    if(props.location.pathname === '/') {
        title = 'Welcome'
    }
    function renderLogout() {

        var s=localStorage.getItem(ACCESS_TOKEN_NAME);
        if(s!=null){
            return(
                
                <div className="ml-auto">
                    <button className="btn btn-danger" onClick={() => handleLogout()}>Logout</button>
                </div>
            )
        }
    }

     function renderMenu() {
        var s=localStorage.getItem(ACCESS_TOKEN_NAME); 
        if(s!=null){
            var firstname=localStorage.getItem('firstname');

            return(
                
                <ul class="dsh_nav navbar-nav ml-auto">
                    
                     <li class="nav-item">
                        <Link class="nav-link" to="/home">Home</Link>
                     </li>
                     <li class="nav-item">
                        <Link class="nav-link" to="/myQrCodes">QR Code List</Link>
                     </li>
                     <li class="nav-item">
                        <Link class="nav-link" to="/generate-qr-codes-url">URL & Text</Link>
                     </li>
                     <li class="nav-item">
                        <Link class="nav-link" to="/generate-qr-codes">Advanced QR Codes</Link>
                     </li>
                     <li class="nav-item dropdown ">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {firstname}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link class="nav-link" to="/profile">Profile</Link>
                           <a class="dropdown-item" href="#" onClick={() => handleLogout()} >Sign Out</a>
                        </div>
                     </li>
                     <li class="nav-item cm_outer5 dh_user">
                        <a class="nav-link disabled" href="#"><img src="img/user_pick.png"/></a>
                     </li>
                </ul>
            )
        }
        //}
    }
    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME)
        props.history.push('/login')
    }
    // <nav className="navbar navbar-dark bg-primary">
    //     <div className="col-12 d-flex justify-content-center text-white">
    //         <span className="h3">{props.title || title}</span>
    //         {renderMenu()}
    //         {renderLogout()}
    //     </div>
    // </nav>
    return(     
        <header>
         <div class="container-fuild">
            <nav class="navbar navbar-expand-lg navbar-light">
               <Link class="nav-link" to="/home"><img src="https://chromeextension.yapits.com/img/logo.png"/></Link>
               <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
               </button>
               <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    {renderMenu()}
               </div>
            </nav>
         </div>
        </header>
    )
}
export default withRouter(Header);