import React, {useState} from 'react';
import './App.css'; 
import Header from './components/Header/Header';
import LoginForm from './components/LoginForm/LoginForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import ForgotPassswordForm from './components/ForgotPassswordForm/ForgotPassswordForm';
import ResetPassword from './components/ResetPassword/ResetPassword';

import ChangePassword from './components/ChangePassword/ChangePassword';
import EditProfile from './components/EditProfile/EditProfile';
import Profile from './components/Profile/Profile';
import Tabs from "./components/Tabs"; 


import Home from './components/Home/Home';
import Urlandtext from './components/Home/UrlAndText';
import Advancedqr from './components/Home/AdvancedQrCodes';



import MyQrCodes from './components/myQrCodes/myQrCodes';
import PrivateRoute from './utils/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AlertComponent from './components/AlertComponent/AlertComponent';  
function App() {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  return (
    <Router>
    <div className="App">
      <Header title={title}/>
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
              <RegistrationForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/register">
              <RegistrationForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/login">
              <LoginForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/resetPassword">
              <ResetPassword showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/ForgotPasssword">
              <ForgotPassswordForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/changepassword">
              <ChangePassword showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/editprofile">
              <EditProfile showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/generate-qr-codes-url">
              <Urlandtext showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <Route path="/generate-qr-codes">
              <Advancedqr showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
          
            <PrivateRoute path="/home">
              <Home showError={updateErrorMessage} updateTitle={updateTitle}/>
            </PrivateRoute>
            <PrivateRoute path="/myQrCodes">
              <MyQrCodes showError={updateErrorMessage} updateTitle={updateTitle}/>
            </PrivateRoute>

            <PrivateRoute path="/profile">
              <Profile showError={updateErrorMessage} updateTitle={updateTitle}/>
            </PrivateRoute>
          </Switch>
          <AlertComponent errorMessage={errorMessage} hideError={updateErrorMessage}/>
        </div>
    </div>
    </Router>
  );
}

export default App;
