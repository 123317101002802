import React, {useState} from 'react';
import axios from 'axios';
import './ForgotPassswordForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter,Link } from "react-router-dom";
 
import QRCode from 'qrcode.react';

function ForgotPassswordForm(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const [ emailErr, setEmailErr ] = useState({});
   
    const formValidation=(type)=>{
       
      	const emailErr={}; 

      	let isValid = true;

      	if(state.email!=''){
      	  var emailField= state.email;
      	  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      	  if (reg.test(state.email) == false) 
      	  {
      	      emailErr.emailEmpty="Please enter a valid E-mail";
      	      isValid= false;
      	  }
      	}
       
      	// setEmailErr(qrEmailErr);
      	setEmailErr(emailErr);
      	// setEmailMessageErr(qrEmailMessageErr); 
      	return isValid;
      

    }
    const handleSubmitClick = (e) => {
        e.preventDefault();
        e.preventDefault();
        const isValid= formValidation(e.target.value);
        // alert(isValid);
        if(isValid==true){


       	 	const payload={
	            "email":state.email
	        }
	       	axios.post(API_BASE_URL+'/forget-password', payload).then(function (response) {
	                if(response.data.status === 200){
	                   	setState({'email':''});
	                    setState(prevState => ({
	                        ...prevState,
	                        'successMessage' : 'System  has sent a reset password link on your email. Please check your email.'
	                    }))
	                    props.showError(null)
	                }
	                else if(response.code == 400){
	                    props.showError("Username and password do not match");
	                }
	                else if(response.code == 404){
	                    props.showError("User does not exists");
	                }
	                else{
	                    props.showError("Username does not exists");
	                }
	        })
	        .catch(function (error) {
	            console.log(error);
	        });
    	}
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToRegister = () => {
        props.history.push('/register'); 
        props.updateTitle('Register');
    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            <form>
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email"  className="form-control"  id="email"  aria-describedby="emailHelp"  placeholder="Enter email" 
                       value={state.email} onChange={handleChange}
                />
                {Object.keys(emailErr).map((key)=>{
                  return <div style={{color:'red'}}>{emailErr[key]}</div>
                })}
                </div>
                 
                <div className="form-check">
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >Submit</button>
            </form>

          
            
            
            <div className="registerMessage">
                <span>Forgot Password? </span>
                <Link to="/login">Login</Link>
            </div>
        </div>
    )
}

export default withRouter(ForgotPassswordForm);