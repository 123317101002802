import React, {useState} from 'react';
import axios from 'axios';
import './LoginForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter,Link } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import QRCode from 'qrcode.react';
const MySwal = withReactContent(Swal)
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

function LoginForm(props) {
    
    const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const handleSubmitClick = (e) => {
        
        e.preventDefault();
        
        const payload={
            "email":state.email,
            "password":state.password,
        }
        const isValid= formValidation();
        //alert(isValid);
        if(isValid==true){
            axios.post(API_BASE_URL+'/login', payload)
            .then(function (response) {

                const invalidDetailsErr={}
                if(response.data.status === 200){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Login successful. Redirecting to home page..'
                    }))
                    //  console.log(response.data.data);
                    //  localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);

                    var mobileNo=response.data.data.mobile?response.data.data.mobile:"";
                    localStorage.setItem('Authorization',response.data.token);
                    localStorage.setItem('firstname',response.data.data.firstname);
                    localStorage.setItem('lastname',response.data.data.lastname);
                    localStorage.setItem('email',response.data.data.email);
                    localStorage.setItem('mobile',mobileNo);

                    Toast.fire({
                        icon: 'success',
                        title: 'Signed In Successfully'
                    })
                    setTimeout(function() {
                        redirectToHome();

                    }, 1000);
                    props.showError(null)
                }else if(response.data.status == 400){
                        invalidDetailsErr.emailEmpty="Username and password do not match";
                        setInvalidDetailsErr(invalidDetailsErr);
                    // props.showError("Username and password do not match");
                }else if(response.data.status == 404){
                    //props.showError("User does not exists");
                    invalidDetailsErr.emailEmpty="Please enter the Password";
                    setInvalidDetailsErr(invalidDetailsErr);
                }else{
                    props.showError("Please fill the details");
                }  
            })
            .catch(function (error) {
                console.log(error);
            });
        }    
    }

    const [ qrPasswordErr, setPasswordErr ] = useState({});
    const [ qrEmailErr, setEmailErr ] = useState({});
    const [ invalidDetailsErr, setInvalidDetailsErr ] = useState({});


    const formValidation=()=>{
        
        const qrEmailErr={}; 
        const qrPasswordErr={}; 

        let isValid = true;
        if(state.email=='' || state.email==undefined){
            qrEmailErr.emailEmpty="Please enter the E-mail";
            isValid= false;
            setEmailErr(qrEmailErr); 
        }else if(state.email!=''){
            var emailField= state.email;
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(state.email) == false) 
            {
                qrEmailErr.emailEmpty="Please enter a valid E-mail";
                isValid= false;
            }
            setEmailErr(qrEmailErr);
            //return isValid;
        }
        // alert(state.password);
        if (state.password=='' || state.password==undefined) {
            //alert('dd');
            qrPasswordErr.emailEmpty="Please enter the Password";
            isValid= false;
        }
        setPasswordErr(qrPasswordErr);
        return isValid;
      

    }
    const redirectToHome = () => {
        // props.updateTitle('Home')
        // props.history.push('/home');
        window.location.href = "/home";
        // window.location.replace(window.location.protocol+"//"+window.location.hostname+"/"+home);
    }
    const redirectToRegister = () => {
        props.history.push('/register'); 
        props.updateTitle('Register');
    }
    if(localStorage.getItem('Authorization')!=null){
        redirectToHome();
    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form> 
                {Object.keys(invalidDetailsErr).map((key)=>{
                  return  <div style={{color:'red'}}>{invalidDetailsErr[key]}</div>
                })}
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       placeholder="Enter email" 
                       value={state.email}
                       onChange={handleChange}
                />
                {Object.keys(qrEmailErr).map((key)=>{
                  return  <div style={{color:'red'}}>{qrEmailErr[key]}</div>
                })}
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-left">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" 
                       className="form-control" 
                       id="password" 
                       placeholder="Password"
                       value={state.password}
                       onChange={handleChange} 
                />
                {Object.keys(qrPasswordErr).map((key)=>{
                  return  <div style={{color:'red'}}>{qrPasswordErr[key]}</div>
                })}
                </div>
                <div className="form-check">
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >Submit</button>
            </form>

          
            
            
            <div className="registerMessage">
                <span>Dont have an account? </span>
                <span className="loginText" onClick={() => redirectToRegister()}>Register</span> 
            </div>
            <div className="registerMessage">
                <span>Forgot Password? </span>
                <Link to="/ForgotPasssword">Forgot Password</Link>
            </div>
        </div>
    )
}

export default withRouter(LoginForm);