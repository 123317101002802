import React, {useState ,useEffect, Component} from 'react';

import axios from 'axios';
import './EditProfile.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
function EditProfile(props) {
    const [state , setState] = useState({
        firstname : "",
        lastname : "",
        email:"",
        mobile: "",
        successMessage: null
    })
    
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    useEffect(() => {  
        localStorage.getItem(ACCESS_TOKEN_NAME);
        let editValue = localStorage.getItem(ACCESS_TOKEN_NAME);
        if (!editValue) {
    
            redirectToHome();
            // Update the document title using the browser API

        }
        if(!state.email){
            setState(() => ({
                "email": localStorage.getItem('email'),
                "mobile":localStorage.getItem('mobile'),
                "firstname":localStorage.getItem('firstname'),
                "lastname":localStorage.getItem('lastname'),
            }))
        }
    });

    const sendDetailsToServer = () => {
        const payload={
            "email":state.email,
            "mobile":state.mobile,
            "firstname":state.firstname,
            "lastname":state.lastname,
            "token" :localStorage.getItem(ACCESS_TOKEN_NAME)
        }

        console.log(state)
        if(state.email.length && state.mobile.length) {
            props.showError(null);
            
            // console.log('gets in')

            axios.post(API_BASE_URL+'/update_profile', payload,{ headers: {'Authorization': localStorage.getItem(ACCESS_TOKEN_NAME)}})
                .then(function (response) {
                    const qrEmailErr={};
                    console.log(response)
                    if(response.data.status === 200){
                        // setState(prevState => ({
                        //     ...prevState,
                        //     'successMessage' : 'Profile update successful.'
                        // }))

                        Toast.fire({
                            icon: 'success',
                            title: 'Profile Updated successfully'
                        })

                        // localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);
                        localStorage.setItem('firstname',state.firstname);
                        localStorage.setItem('lastname',state.lastname);
                        localStorage.setItem('email',state.email);
                        localStorage.setItem('mobile',state.mobile);
                        // console.log(response.data.data2.email);

                
                        props.showError(null)
                    } else if(response.data.status==400){
                        qrEmailErr.emailEmpty="This E-mail address is already taken";
                        setEmailErr(qrEmailErr);
                    }else{
                        props.showError("Some error ocurred");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });    
        } else {
            props.showError('Please enter phone number')    
        }
        
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToLogin = () => {
        props.updateTitle('Login')
        props.history.push('/login'); 
    }
    const handleSubmitClick = (e) => {
        e.preventDefault();

        const isValid= formValidation(e.target.value);
        if(isValid==true){
          sendDetailsToServer(e); 
        }
        // if(state.password === state.confirmPassword) {
        //     sendDetailsToServer()    
        // } else {
        //     props.showError('Passwords do not match');
        // }
    }
  
    const [ qrPasswordErr, setPasswordErr ] = useState({});
    const [ qrEmailErr, setEmailErr ] = useState({});
    const [ firstNameErr, setFirstNameErr ] = useState({});
    const [ lastNameErr, setLastNameErr ] = useState({});
    const [ mobileErr, setMobileErr ] = useState({});

    const formValidation=()=>{
        
        const qrEmailErr={};   
        // const qrPasswordErr={}; 
        const lastNameErr={}; 
        const firstNameErr={}; 
        const mobileErr={}; 

        let isValid = true;
        if(state.email=='' || state.email==undefined){
            qrEmailErr.emailEmpty="Please enter the E-mail";
            isValid= false;
             
        }
        if(state.email!=''){
            var emailField= state.email;
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (reg.test(state.email) == false) 
            {
                qrEmailErr.emailEmpty="Please enter a valid E-mail";
                isValid= false;
            } 
            //return isValid;
        } 
        if (state.firstname=='' || state.firstname==undefined) {
            //alert('dd');
            firstNameErr.emailEmpty="Please enter the first name";
            isValid= false;
        }
        if (state.lastname=='' || state.lastname==undefined) {
            //alert('dd');
            lastNameErr.emailEmpty="Please enter the lastname name";
            isValid= false;
        }
        if (state.mobile=='' || state.mobile==undefined) {
            //alert('dd');
            mobileErr.emailEmpty="Please enter mobile number";
            isValid= false;
        }
        // setPasswordErr(qrPasswordErr);
        setEmailErr(qrEmailErr);
        setFirstNameErr(firstNameErr);
        setLastNameErr(lastNameErr);
        setMobileErr(mobileErr);
        return isValid;
      

    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
             <div className="form-group text-left">
                    <label htmlFor="exampleInputFirstname">First name</label>
                    <input type="text" className="form-control" 
                        id="firstname"  defaultValue={localStorage.getItem('firstname') || ''} value={state.firstname}
                        onChange={handleChange} />
                        {Object.keys(firstNameErr).map((key)=>{
                          return  <div style={{color:'red'}}>{firstNameErr[key]}</div>
                        })}
                </div>
                  <div className="form-group text-left">
                    <label htmlFor="exampleInputLastname">Last name</label>
                    <input type="text"  className="form-control"  id="lastname"  defaultValue={localStorage.getItem('lastname') || ''} 
                       value={state.lastname} 
                       onChange={handleChange} 
                    />
                    {Object.keys(lastNameErr).map((key)=>{
                      return  <div style={{color:'red'}}>{lastNameErr[key]}</div>
                    })}
                </div>
               
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email"  className="form-control"  id="email"  aria-describedby="emailHelp" 
                    defaultValue={localStorage.getItem('email') || ''} value={state.email} onChange={handleChange} />
                {Object.keys(qrEmailErr).map((key)=>{
                  return  <div style={{color:'red'}}>{qrEmailErr[key]}</div>
                })}
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Phone number</label>
                    <input type="text"  className="form-control"  id="mobile"  defaultValue={localStorage.getItem('mobile') || ''} value={state.mobile}
                        onChange={handleChange}  />
                {Object.keys(mobileErr).map((key)=>{
                  return  <div style={{color:'red'}}>{mobileErr[key]}</div>
                })}
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    submit
                </button>
            </form>
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
           
            
        </div>
    )
}

export default withRouter(EditProfile);