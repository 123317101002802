//export const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
// export const API_BASE_URL = 'http://localhost:5003';
// export const WEB_URL = 'http://localhost:3000';
// export const IMG_PATH = 'http://localhost:5003/uploads/';

export const API_BASE_URL = 'https://backend-chromeextension.yapits.com';
export const WEB_URL = "https://chromeextension.yapits.com/";
export const IMG_PATH = 'https://backend-chromeextension.yapits.com/uploads';
export const ACCESS_TOKEN_NAME = 'Authorization';


