import React, {useState} from 'react';
import axios from 'axios';
// import './ForgotPassswordForm.css';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import { withRouter,Link } from "react-router-dom";
 
import QRCode from 'qrcode.react';

function ResetPassword(props) {
    const [state , setState] = useState({
        email : "",
        password : "",
        successMessage: null
    })
    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const [ confirmPasswordErr, setConfirmpassword ] = useState({});
    const [ passwordErr, setPassword ] = useState({});
   
    const formValidation=(type)=>{
       
      const confirmPasswordErr={}; 
      const passwordErr={}; 

      let isValid = true;
      if (state.password=='' || state.password==undefined) {
            //alert('dd');
            passwordErr.emailEmpty="Please enter the Password";
            isValid= false;
      }
      if (state.confirmPassword=='' || state.confirmPassword==undefined) {
            //alert('dd');
            confirmPasswordErr.emailEmpty="Please enter confirm password";
            isValid= false;
      }else if(state.password!= state.confirmPassword){
          confirmPasswordErr.urlEmpty="Confirm Password mismatch..";
          isValid= false;
      }
       
       
    	// setEmailErr(qrEmailErr);
      setConfirmpassword(confirmPasswordErr);
    	setPassword(passwordErr);
    	// setEmailMessageErr(qrEmailMessageErr); 
    	return isValid;
      

    }
    const handleSubmitClick = (e) => {
        e.preventDefault();
        const isValid= formValidation(e.target.value);
        // alert(isValid);
        if(isValid==true){

        	const url = window.location.href;
			const lastSegment = url.split("/").pop();
          	//sendDetailsToServer(e);
	        const payload={
	            "password":state.password,
	            "confirmPassword":state.confirmPassword,
	            "token":lastSegment
	        }
	       axios.post(API_BASE_URL+'/reset-password', payload).then(function (response) {
	            if(response.data.status === 200){
	                setState(prevState => ({
	                    ...prevState,
	                    'successMessage' : 'Password reset successfully'
	                }))

	                redirectToLogin();
	                props.showError(null)
	            }
	            else if(response.code == 400){
	                props.showError("Username and password do not match");
	            }
	            else if(response.code == 404){
	                props.showError("User does not exists");
	            }
	            else{
	                props.showError("All fields are required");
	            }
	        })
	        .catch(function (error) {
	            console.log(error);
	        });
        }
    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToLogin = () => {
        props.history.push('/login'); 
        props.updateTitle('Login');
    }
    return(
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            
            <form>
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Password address</label>
                <input type="password"  className="form-control"  id="password"  placeholder="Password" value={state.password}
                       onChange={handleChange} />
                {Object.keys(passwordErr).map((key)=>{
                      return <div style={{color:'red'}}>{passwordErr[key]}</div>
                    })}
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" 
                    value={state.confirmPassword} onChange={handleChange} />
                    {Object.keys(confirmPasswordErr).map((key)=>{
                      return <div style={{color:'red'}}>{confirmPasswordErr[key]}</div>
                    })}
                </div>
                <div className="form-check">
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >Submit</button>
            </form>

          
            
            <div className="alert alert-success mt-2" style={{display: state.successMessage ? 'block' : 'none' }} role="alert">
                {state.successMessage}
            </div>
            <div className="registerMessage">
                <span>Forgot Password? </span>
                <Link to="/login">Login</Link>
            </div>
        </div>
    )
}

export default withRouter(ResetPassword);